import React from "react"
import Layout from "../../../components/Layout"
import Nav from "../../../components/Nav"

// Images
import hubspotHero from '../../../images/hubspot-network-hero.png'
import designSprintFull from '../../../images/hubspot-design-sprint-full.png'
import designSprint1 from '../../../images/hubspot-design-sprint-1.png'
import designSprint2 from '../../../images/hubspot-design-sprint-2.png'
import designSprint3 from '../../../images/hubspot-design-sprint-3.png'
import designSprint4 from '../../../images/hubspot-design-sprint-4.png'
import hubSpotPrototype1 from '../../../images/hubspot-initial-prototype.png'
import networkMyProfile from '../../../images/hubspot-network-my-profile.png'
import networkSettingsGoal from '../../../images/hubspot-network-settings-page-goal.png'
import networkSettings from '../../../images/hubspot-network-settings-page.png'
import networkDirectory from '../../../images/hubspot-network-directory.png'

export default function CaseStudy() {
    return (
        <Layout>
            <Nav></Nav>
            <div className="case-study">
                <main>
                    <div className="wrapper">
                        <div className="case-study--hero">
                            <span className="lozenge">Case study</span>
                            <h1>Networking in a LinkedIn world</h1>
                            <h2>Does there need to be another network to unite those who subscribe to the HubSpot way?</h2>
                            <img src={hubspotHero} alt="" />
                        </div>
                        <div className="flex-h">
                            <section className="case-study--background flex-60">
                                <h3>Background</h3>
                                <p>According to a <a href="https://www.hubspot.com/company-news/hubspot-ecosystem-white-paper" rel="noreferrer" >IDC White Paper</a>, sponsored by HubSpot, there is nearly $19 billion in new opportunity ahead for HubSpot Partners specifically.</p>
                                <blockquote>“The white paper quantifies the revenue generated by the thousands of app and solutions partners that provide the products and services that accompany HubSpot’s CRM platform software. The global HubSpot partner ecosystem is projected by IDC to grow from $4.8 billion in revenue in 2020 to $12.5 billion in 2024, <strong>representing an additional $18.8 billion in revenue above and beyond the 2020 level over the next four years</strong>.” </blockquote>
                            </section>
                            <section className="case-study--details flex-40">
                                <div className="flex-h">
                                    <dl>
                                        <dt>Role</dt>
                                        <dd>Lead designer</dd>
                                    </dl>
                                    <dl>
                                        <dt>Contribution</dt>
                                        <dd>Design Sprint</dd>
                                        <dd>Prototyping</dd>
                                        <dd>Research</dd>
                                    </dl>
                                    <dl>
                                        <dt>Collaborators</dt>
                                        <dd>Web Strategist</dd>
                                        <dd>Engineering counterpart</dd>
                                        <dd>Product manager</dd>
                                    </dl>
                                </div>
                            </section>
                        </div>
                        <section className="case-study--problem">
                                <h3>Problem statement</h3>
                                <p>For HubSpot Partners - although their agencies as a whole have representation within the marketplace ecosystem, individually as knowledgable HubSpot trained people was missing. Beyond their individual LinkedIn profiles, which requires a great deal of manual updating of certifications from the HubSpot Academy, and website biographies, nothing truly exists to demonstrate the level and depth of these agency employees. </p>
                                <p>For HubSpot - If the ecosystem revenue potential is to be truly realized, the full portfolio of what takes a HubSpot Partner to succeed should be owned and operated by HubSpot, to the benefit of HubSpot Partners. In effect, it’s owning the full end-to-end experience so that we can best support Partners where they need it the most.</p>
                        </section>
                        <section className="case-study--business-metrics">
                            <h3>Business metrics</h3>
                            <ol>
                                <li>With this being so nascent, there were to business metrics associated with the body of work for this network.</li>
                            </ol>
                        </section>
                        <section className="case-study--design-process">
                            <div>
                                <h3>Design process</h3>
                                <div className="double-diamond">
                                    <div className="double-diamond-section">
                                        <h3>Discover</h3>
                                        <p>How might we understand whom the network is for?</p>
                                        <ul>
                                            <li>Design sprint workshop</li>
                                        </ul>
                                    </div>
                                    <div className="double-diamond-section">
                                        <h3>Define</h3>
                                        <p>Of all the things a HubSpot profile can be, how should it display credibility?</p>
                                        <ul>
                                            <li>Visual design</li>
                                        </ul>
                                    </div>
                                    <div className="double-diamond-section">
                                        <h3>Develop</h3>
                                        <p>Limited to just basic features and functions, what should this experience look and feel like? </p>
                                        <ul>
                                            <li>UX Design</li>
                                            <li>Usability</li>                               
                                        </ul>
                                    </div>
                                    <div className="double-diamond-section">
                                        <h3>Deliver</h3>
                                        <p>Redlines for dev</p>
                                        <ul>
                                            <li>Specs documents</li>
                                        </ul>
                                    </div>
                                </div>
                                <section id="discovery">
                                    <h4>Discovery phase</h4>
                                    <p><em>Discover insights into the problem by uncovering the problem space</em></p>
                                    <p>Since this was such a new initiative, there were two unknowns: </p>
                                    <ol>
                                        <li>What did our internal stakeholders think and we were aligned?</li> 
                                        <li>Who were our customers/users?</li>
                                    </ol>
                                    <p>As a way to understand both of these unknowns, I advocated for a design sprint with internal stakeholders from across the organization. From that design sprint, we would take our prototype to actual customers and then I would report back my findings. </p>
                                    <h5>Design sprint</h5>
                                    <p>I planned three separate sessions for this design sprint. Essentially removing the prototyping and testing portion that normally happens and focusing more on the Learning, Noting, and Collaboration exercises.</p>
                                    <img src={designSprintFull} alt="" />
                                </section>
                                <section>
                                    <h5>Session 1 - Long term goals</h5>
                                        <p><strong>Being super optimistic, if everything goes perfectly from now until launch day and beyond, where will we be in 12-18 months?</strong></p>
                                        <div className="flex-h">
                                            
                                            <div>
                                                <p>Top voted: "There will be more people on HubSpot Network than LinkedIn and member profiles will surface in all media articles/posts and resumes."</p>
                                                <p>Notable: “HubSpot profiles/directory are the #1 way users are getting their dream roles and growing their businesses.”</p>
                                            </div>
                                            <img src={designSprint1} alt="" width="600" />
                                        </div>
                                </section>
                                <section>
                                    <h5>Session 1 - Lightning talks + How Might We's</h5>
                                    <p>I had speakers give lightning talks across various parts of the business. From these talks participants wrote down “How Might We” questions as thought provocations. </p>
                                    <img src={designSprint2} alt=""  />
                                    <div className="flex-h">
                                        <div>
                                            <p>Some themes started to emerge:</p>
                                            <ul>
                                                <li>Connection</li>
                                                <li>Exposure</li>
                                                <li>Validation</li>
                                                <li>Functionality of the profile</li>
                                                <li>Partner experience</li>
                                                <li>Internal HubSpot experiences</li>
                                            </ul>
                                            
                                        </div>
                                        <div>
                                            <p>Top voted HMWs</p>
                                            <ul>
                                                <li>How might we foster peer to peer connections in the HS network?</li>
                                                <li>HMW calculate ROI for our users investing in HubSpot?</li>
                                                <li>HMW determine all the different aspects that make up “reputation”?</li>
                                                <li>HMW make it easy to match customers needs with providers and best surface this?</li>
                                                <li>HMW incentivize more people to share their credentials in non-HubSpot locations/sites?</li>
                                                <li>HMW develop this reputation into currency?</li>
                                                <li>HMW amplify diverse voices in our network?</li>
                                                <li>HMW make it clear who the best service providers are for each service?</li>
                                                <li>HMW make sure we don’t have badge inflation?</li>
                                                <li>HMW leverage credentials without diluting their value? </li>
                                            </ul>
                                        </div>
                                    </div>
                                </section>
                                <section>
                                    <h5>Session 2 - Note N Map</h5>
                                    <p>I asked our participants to think through our customer’s POV as well as our HubSpot Partners and map the experience of what it means to “Learn”, “Earn” and “Share”. Where “learn” is the start of their learning journey, “earn” are the actions they’ve taken to earn something of recognition from HubSpot, and “Share” where they have shared what they have earned. </p>
                                    <p>I asked our participants to do this individually, and then collectively as a group and present their maps.</p>
                                </section>
                                <section>
                                    <h5>Session 3 - Brainstorming</h5>
                                    <p>Crazy 8's, Individual and team solution sketches</p>
                                    <p>Through a series of brainstorm exercises, I led the whole design sprint team into various ways to brainstorm and quickly iterate on ideas and generate more ideas of what a HubSpot profile page might look like based on everything we had heard and mapped out.</p>
                                    <img src={designSprint3} alt="" />
                                    <img src={designSprint4} alt="" />
                                </section>

                                <section>
                                    <h5>What did we learn?</h5>
                                    <p>Validation of existing credentials is a big thing. When thinking about the manifestation of the profile page, how can HubSpot be extra attentive to these credentials certifications, badges, etc.) that our customers have earned. The need to connect to one another is highly rampant theme, but not specific enough in what kind of connection should be facilitated and to what end. </p>
                                </section>
                                <section id="develop">
                                    <h4>Develop</h4>
                                    <p><em>Develop potential solutions aka prototyping</em></p>
                                    <p>After discovering what we wanted to pursue from an internal HubSpot perspective, I wanted to determine how much of this vision resonates with our users. I set up five interviews with customers and HubSpot Partners. </p>
                                    <ol>
                                        <li>I wanted to know how they used HubSpot in their day-to-day lives.</li>
                                        <li>I gave them an opportunity to build their own profile page using pre-defined sticky notes, and allowed them to rate each element's value of importance.</li>
                                        <li>Ran the same exercise but this time for someone else's profile page with the same value assignment.</li>
                                        <li>Lastly, I asked them to play around with a prototype of a profile and directory pages.</li>
                                    </ol>
                                    <blockquote style={{marginTop:`4rem`,marginBottom:`4rem`}}>The biggest insights we gained were in the way they valued elements on their profile page and the values of elements on other people's profile pages. <strong>Namely, the overlap consisted of elements such as Demonstration of  HubSpot expertise, the person's name, and the lack of interest in anything social media related.</strong></blockquote>
                                </section>
                                <section>
                                    <h4>Prototype feedback</h4>
                                    <p>It was important to gauge feedback from customers regarding a perspective of the profile page that the internal design sprint team had come up with.</p>
                                    <div className="flex-h">
                                        <img src={hubSpotPrototype1} alt="" width="800" />
                                        <div>
                                            <p><strong>Feedback</strong></p>
                                            <p>In general, it was positive that it was cool to see HubSpot working on something like this. Many participants pointed to how a lot of their efforts in the HubSpot ecosystem were pulled into one view and this was helpful to them. These were mainly the people who were HubSpot Partners and could see how this could help with the onboarding of a new client and winning their business.</p>
                                            <p>It was interesting to point out that participants were not totally clear as to the overall purpose of this network. </p>
                                            <blockquote>"Like what's their objective? Like what, like what are they trying to achieve? Are they just trying to get hired or work or do they want to take on certain projects?"</blockquote>
                                            <blockquote>"Looks a lot like LinkedIn, I'll say that again."</blockquote>
                                        </div>
                                    </div>
                                </section>
                                <section id="deliver">
                                    <h4>Deliver</h4>
                                    <p><em>Deliver an experience with confidence</em></p>
                                    <p>Due to some organizational changes, the Network project headed towards the MVP conversation and was stripped of a lot of potential features that could have been explored futher. Namely, connecting of people and doing so in a novel and clever way, especially in the effort of bolstering the HubSpot Partner ecosystem.</p>
                                    <p>That being said, the focus on just the profile page and directory (and the settings page to input all of the data), resulted in the following designs as the version that was shipped behind a beta release.</p>
                                    <p>My profile page</p>
                                    <img src={networkMyProfile} />
                                    <p>Settings page</p>
                                    <img src={networkSettings} />
                                    <p>Settings page - Goal selection</p>
                                    <img src={networkSettingsGoal} />
                                    <p>Someone else's profile page</p>
                                    <img src={hubspotHero} />
                                    <p>Directory page</p>
                                    <img src={networkDirectory} />
                                </section>
                            </div>
                        </section>
                    </div>
                </main>
            </div>
        </Layout>
        
    )
}